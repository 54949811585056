import React, { useCallback, useEffect } from "react";
import { useSearchParams, createSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from "recoil";
import { Box, Typography, useMediaQuery } from "@mui/material";
import InfiniteScroll from 'react-infinite-scroll-component';
import DataTable from 'react-data-table-component';
import styled from '@emotion/styled';

import { smallTableStyles, tableStyles } from "./styles/tableStyles";
import { getColoredOrders, getExpandedOrders, getOrders, getPatchedRows } from "./utils/order";
import { paginationLength } from "../../const/pagination";
import conditionalRowStyles from './styles/conditionalRowStyles'
import getColumns from './columns';

import { ordersAtom } from "../atoms/order";
import { rowGroupSelect, rowSelect } from "../atoms/row";
import { isUserLoggedAtom } from "../atoms/isUserLogged";
import { commentPopupAtom } from "../atoms/commentPopupAtom";
import { userAtom } from "../atoms/user";
import { clubsAtom } from "../atoms/clubs";

import { CustomCheckbox } from "./customCheckbox";
import HeaderTable from './tableHeader'
import SnackBar from './snackBar'
import CommentPopup from "./commentPopup";
import Loading from "../loader";
import Header from "../header";
import { localizationAtom } from "../atoms/localization";
import usePolling from "../../hooks/usePolling";
import { getCounters } from "../api/counters";
import { countersAtom } from "../atoms/counters";

export default function TableOrder() {
  const isMac = useMediaQuery('(max-width: 1440px)');
  const [offset, setOffset] = React.useState(0);
  const [filterOffset, setFilterOffset] = React.useState(0);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  const [searchParams, setSearchParams] = useSearchParams({ status: "New" });
  const [expandRow] = useRecoilState(rowGroupSelect);
  const [selectedRows, setSelectedRows] = useRecoilState(rowSelect)

  const [, setIsUserLogged] = useRecoilState(isUserLoggedAtom);
  const clubs = useRecoilValue(clubsAtom);
  const user = useRecoilValue(userAtom);
  const localization = useRecoilValue(localizationAtom);

  const isUserSuperadmin = user?.role === "superadmin";
  const isUserManager = user?.role === "manager";

  const [columns, setColumns] = React.useState(getColumns(isUserSuperadmin || isUserManager));

  // Orders
  const [orderData, setOrderData] = useRecoilState(ordersAtom);
  const [, setCounters] = useRecoilState(countersAtom);

  const [searchValue, setSearchValue] = React.useState('');
  const [searchTimeoutId, setSearchTimeoutId] = React.useState(null);
  const [filteredOrders, setFilteredOrders] = React.useState([]);
  // const [expandedOrders, setExpandedOrders] = React.useState([]);

  const [commentPopupData, setCommentPopupData] = useRecoilState(commentPopupAtom);

  const [masterCheckboxStatus, setMasterCheckboxStatus] = React.useState({ isChecked: false, isIndeterminate: false });


  //filter 
  const updateFilter = (newFilters) => {
    setHasMoreData(true);
    setSearchParams((prev) => {
      const curr = new URLSearchParams(prev);

      Object.entries(newFilters).forEach(([key, value]) => {
        if (value === null) {
          curr.delete(key);
        } else {
          curr.set(key, value);
        }
      });

      return curr;
    });
  }

  // Patch data
  const fetchOrders = useCallback((offset) => {
    if (user && clubs) {
      const newSearchParams = createSearchParams(searchParams);

      if (!isUserSuperadmin && !isUserManager) {
        newSearchParams.set("club", user.clubs[0].pk);
      }

      setIsLoading(true);

      getOrders(newSearchParams, offset, searchValue)
        .then(([{ newOrders, has_more_data: hasMoreData }, error]) => {
          if (error && error?.response?.status === 401) {
            setIsUserLogged(false);
            return;
          }

          setHasMoreData(hasMoreData);

          if (searchParams.get('status') === 'Registered') {
            if (offset === 0) {
              setOrderData(getColoredOrders(getExpandedOrders(newOrders), searchParams.get('status')));
            } else {
              setOrderData(getColoredOrders(getExpandedOrders([...filteredOrders, ...newOrders]), searchParams.get('status')));
            }

          } else if (searchValue) {
            if (isUserSuperadmin || isUserManager) {
              setFilteredOrders(getColoredOrders([...filteredOrders, ...newOrders], searchParams.get('status')));
            } else {
              setFilteredOrders(getColoredOrders(getExpandedOrders([...filteredOrders, ...newOrders]), searchParams.get('status')));
            }

          } else {
            if (offset === 0) {
              if (isUserSuperadmin || isUserManager) {
                setOrderData(getColoredOrders(newOrders, searchParams.get('status')));
              } else {
                setOrderData(getColoredOrders(getExpandedOrders(newOrders), searchParams.get('status')));
              }

            } else {
              if (isUserSuperadmin || isUserManager) {
                setOrderData(getColoredOrders([...filteredOrders, ...newOrders], searchParams.get('status')));
              } else {
                setOrderData(getColoredOrders(getExpandedOrders([...filteredOrders, ...newOrders]), searchParams.get('status')));
              }
            }
          }

          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.error("Ошибка получения заказов");
        });
    }
  }, [clubs, filteredOrders, searchParams, searchValue, setIsUserLogged, setOrderData, user]);

  const fetchCounters = useCallback(() => {
    getCounters()
      .then((newCounters) => {
        setCounters(newCounters.data);
      })
      .catch(console.error);
  }, [])

  const handleFetchOrders = useCallback((reset = false) => {
    const newOffset = reset
      ? 0
      : searchValue
        ? filterOffset + paginationLength
        : offset + paginationLength;

    if (searchParams.get('status')) {
      fetchOrders(newOffset);

      if (searchValue) {
        setFilterOffset(newOffset);
      } else {
        setOffset(newOffset);

      }
    }
  }, [searchParams, filterOffset, fetchOrders, offset, searchValue]);

  const ExpandedComponent = ({ data }) => {
    const innerTableColumns = columns;

    // Убираю Email у вложенных заказов
    innerTableColumns
      .find((column) => column.name === 'Email').selector = () => (null);

    return (
      <DataTable
        columns={innerTableColumns}
        data={data.group_orders_24}
        customStyles={smallTableStyles}
        conditionalRowStyles={conditionalRowStyles(searchParams.get('status') || '', expandRow, data.shouldBeColored)}

      />
    )
  }

  const expandableRowExpanded = (row) => {
    if (expandRow.includes(row.id_year) && row.is_last === true) {
      return true;

    }
  }
  // const rowPreDisabled = () => true;

  const handleMasterCheckboxChange = (evt) => {
    const isChecked = evt.target.checked;
    // setMasterCheckboxStatus(prev => ({ ...prev, isChecked }));

    if (!isChecked) {
      setSelectedRows([]);
    } else {
      const ids = new Set();

      const selectedRows = filteredOrders
        .reduce((acc, row) => {
          if (row.group_orders_24?.length > 0 && !ids.has(row.id_year)) {
            acc.push(...row.group_orders_24);
            ids.add(row.id_year);
          }

          return [...acc, row];
        }, []);

      setSelectedRows(selectedRows);
    }
  };

  async function handleCommentChange(payload) {
    const patchedOrders = await getPatchedRows(payload, orderData);
    setOrderData(patchedOrders);
  }

  usePolling(handleFetchOrders);
  usePolling(fetchCounters);

  // Если поисковый запрос пустой - записать все заказы в отфильтрованные заказы
  useEffect(() => {
    setFilteredOrders(() => {
      if (searchValue.length === 0) {
        return orderData;
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  // Запрос отфильтрованных заказов при изменении поискового поля
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function loadFilteredOrders() {
      const [{ newOrders, has_more_data: hasMoreData }, error] = await getOrders(searchParams, 0, searchValue, signal);

      if (error) {
        console.log("Error getting orders");
        console.log(error);
        return;
      }

      setIsLoading(false);
      setFilteredOrders(getColoredOrders(newOrders, searchParams.get('status')));
      setHasMoreData(hasMoreData);
    }

    if (searchValue.length < 3 && isNaN(+searchValue[0])) {
      setFilteredOrders(orderData);

      setIsLoading(false);
      setHasMoreData(true);
      clearTimeout(searchTimeoutId);
      setSearchTimeoutId(null);
      return;
    }

    if (searchTimeoutId) {
      clearTimeout(searchTimeoutId);
      setSearchTimeoutId(null);
    }

    setIsLoading(true);
    setFilteredOrders([]);
    const timeoutId = setTimeout(() => {
      loadFilteredOrders();

    }, 500);

    setSearchTimeoutId(timeoutId);

    return () => {
      controller.abort(); // Отмена предыдущего запроса при изменении searchTerm
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (user) {
      setOrderData([]);
      handleFetchOrders(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, user]);

  useEffect(() => {
    if (selectedRows?.length === 0) {
      setMasterCheckboxStatus({ isChecked: false, isIndeterminate: false });

    } else if (selectedRows?.length >= filteredOrders?.length) {
      setMasterCheckboxStatus({ isChecked: true, isIndeterminate: false });

    } else {
      setMasterCheckboxStatus({ isChecked: false, isIndeterminate: true });

    }
  }, [selectedRows, filteredOrders]);

  useEffect(() => {
    if (user) {
      setColumns(getColumns(isUserSuperadmin || isUserManager, localization, isMac));

      if (!isUserSuperadmin && !isUserManager && user?.club) {
        setSearchParams((prev) => {
          const curr = createSearchParams(prev);

          curr.set("club", user?.club);

          return curr;
        });
      }
    }
  }, [user, localization, isMac, setSearchParams]);


  return (
    <CustomContainer isExtended={isUserSuperadmin || isUserManager}>
      <Header
        updateFilter={updateFilter}
        searchParams={searchParams}
        setSearchValue={setSearchValue}
      />
      <HeaderTable
        updateFilter={updateFilter}
        searchParams={searchParams}
        setSearchValue={setSearchValue}
        searchValue={searchValue}

      />
      <SnackBar props={selectedRows} />

      <InfiniteScroll
        dataLength={filteredOrders?.length}
        hasChildren={false}
        next={handleFetchOrders}
        hasMore={hasMoreData}
        endMessage={
          filteredOrders?.length > 0 && (
            <Typography sx={{ textAlign: 'center', fontWeight: 'bold', my: 2.5 }}>{localization['table_end_message']}</Typography>
          )
        }
      >
        <WraperTable>
          {(filteredOrders?.length > 0 || !isLoading) && (
            <DataTable
              columns={columns}
              data={filteredOrders}
              customStyles={tableStyles}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              // expandableRowDisabled={rowPreDisabled}
              conditionalRowStyles={conditionalRowStyles(searchParams.get('status') || '', expandRow)}
              expandableRowExpanded={expandableRowExpanded}
              expandableIcon={{ collapsed: <div />, expanded: <div /> }}
            />
          )}

          {filteredOrders?.length > 0 && (isUserSuperadmin || isUserManager) && (
            <MasterCheckbox
              size="small"
              // style={{ opacity: .7 }}
              isMac={isMac}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={handleMasterCheckboxChange}
              icon={<></>}
              checkedIcon={<></>}
              indeterminateIcon={<></>}
              checked={masterCheckboxStatus.isChecked}
              indeterminate={masterCheckboxStatus.isIndeterminate}
            />
          )}
        </WraperTable>
      </InfiniteScroll>

      {commentPopupData && (
        <CommentPopup
          isOpen={true}
          selectedRow={commentPopupData}
          onClose={() => setCommentPopupData(null)}
          onCommentChange={handleCommentChange}
        />

      )}

      {isLoading && (
        <Loading />
      )}

    </CustomContainer>
  );
}

const CustomContainer = styled(Box)(({ isExtended }) => ({
  maxWidth: isExtended ? 1728 : 1280,
  width: '100%',
  margin: '0 auto',

}));

const WraperTable = styled('div')({
  padding: '0 10px',
  position: 'relative',
});

const MasterCheckbox = styled(CustomCheckbox)(({ isMac }) => ({
  position: 'absolute',
  top: 14,
  left: isMac ? 28 : 43,
}));