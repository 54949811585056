import { useEffect, useRef } from "react";
import { checkNewOrders } from "../components/api/checkNew";

const usePolling = (pollingFunction, rawInterval = 120000) => {
  const lastUpdated = useRef(new Date());
  const timerRef = useRef(null);
  let interval;

  useEffect(() => {
    const dateObject = new Date();
    const secondsToNextEvenMinute = ((dateObject.getMinutes() + 1) % 2 + 1) * 60;
    const millisecondsToNextEvenMinute = (secondsToNextEvenMinute - dateObject.getSeconds()) * 1000;
    interval = millisecondsToNextEvenMinute;

    const startTimer = () => {
      timerRef.current = setTimeout(() => {
        checkNewOrders(
          lastUpdated.current.toISOString().replace("T", " ").slice(0, 19)
        )
          .then(({ data }) => {
            if (data.has_new) {
              pollingFunction(true);
            }

            // Обновляем время последнего обновления
            lastUpdated.current = new Date();
          })
          .catch((error) => {
            console.error("Ошибка получения данных:", error);
          })
          .finally(() => {
            interval = rawInterval;
            startTimer(); // Запускаем таймер снова
          });
      }, interval);
    };

    // Запускаем таймер
    startTimer();

    // Очищаем таймер при размонтировании компонента
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [pollingFunction, rawInterval]);
};

export default usePolling;
