import React from "react";
import { Chip } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { rowGroupSelect } from '../../atoms/row'

import ExpandButton from "../expandButton";
import { clubsAtom } from "../../atoms/clubs";


export default function Club({ row, name }) {
  const [expandRow, setExpandRow] = useRecoilState(rowGroupSelect);
  const clubs = useRecoilValue(clubsAtom);

  const club = clubs?.all?.[row[name]]
  const color = row.shouldHaveAccentBackground ? 'rgba(255, 255, 255, 0.80)' : club?.color;

  if (!club) {
    return null;
  }

  if (expandRow.includes(row.id_year)) {
    if (row.is_view === true) {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignContent: 'flex-start',
          gap: '4px',
          flexWrap: 'nowrap',
          backgroundColor: 'transparent',
        }}
        >
          <Chip
            label={(club?.short_name || club?.name).toUpperCase()}
            variant={club?.name?.toLowerCase()}
            sx={{
              color: color,
              border: `1px solid ${color}`,
              backgroundColor: 'transparent',
              fontWeight: 1000,
              height: '26px'
            }}
          />

          <ExpandButton
            isOpen={true}
            onClick={() => setExpandRow(expandRow.filter(item => item !== row.id_year))}
          />
        </div>
      );
    }
  }

  if (row.is_view === true) {
    const uniqueKeys = new Set();
    uniqueKeys.add(row[name]);

    if (row.group_orders_24.length > 0) {
        for (const obj of row.group_orders_24) {
          uniqueKeys.add(obj.id_year);
        }

      return (
        <ExpandButton
          isOpen={false}
          label={uniqueKeys.size}
          onClick={() => setExpandRow([...expandRow, row.id_year])}
        />
      )

    } else {
      return (
        <Chip
          label={(club?.short_name || club?.name).toUpperCase()}
          variant={club?.name?.toLowerCase()}
          sx={{
            color: color,
            border: `1px solid ${color}`,
            backgroundColor: 'transparent',
            fontWeight: 1000,
            height: '26px'
          }}
        />
      )
    }
  } else {
    return null;
  }
}