import { axiosInstance } from "./axiosInstanse"


export const getOrderData = (params, signal) => {
    return axiosInstance.get(`orders/`, { params, signal })
}


export const patchOrderData = (data) => {
  return axiosInstance.patch(`orders/`, {data: data})
}